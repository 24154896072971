import { Component, inject } from '@angular/core';
import { PublicationsComponent, SimpleBannerComponent } from '@cportal/components';
import { Store } from '@ngrx/store';
import { selectSalesMaterialsPublications } from '../shared/store/sales-materials/sales-materials.selectors';
import { toSignal } from '@angular/core/rxjs-interop';
import { PublicationAction, PublicationJson } from '@cportal/api';
import { downloadFile } from '../shared/utils';

@Component({
  selector: 'cp-sales-materials',
  standalone: true,
  imports: [SimpleBannerComponent, PublicationsComponent],
  templateUrl: './sales-materials.component.html',
  styleUrl: './sales-materials.component.scss',
})
export class SalesMaterialsComponent {
  store = inject(Store);

  publications = toSignal(this.store.select(selectSalesMaterialsPublications), {
    initialValue: [],
  });

  handleClick(publication: PublicationJson) {
    if (publication.action === PublicationAction.Open) {
      window.open(publication.linkUrl, '_blank');
    } else if (publication.action === PublicationAction.Download) {
      downloadFile(`${publication.linkUrl}`);
    }
  }
}
