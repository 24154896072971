import { createReducer, on } from '@ngrx/store';
import { SalesMaterialsApiActions } from './sales-materials.actions';
import { PublicationJson } from '@cportal/api';

export const salesMaterialsFeatureKey = 'sales-materials';

export interface SalesMaterialsState {
  publications: readonly PublicationJson[];
  isLoading: boolean;
  error: any;
}

export const initialState: Readonly<SalesMaterialsState> = {
  publications: [],
  isLoading: false,
  error: undefined,
};

export const salesMaterialsReducer = createReducer(
  initialState,
  on(SalesMaterialsApiActions.retrievePublications, state => ({
    ...state,
    isLoading: true,
  })),
  on(SalesMaterialsApiActions.retrievePublicationsSuccess, (state, { publications }) => ({
    ...state,
    isLoading: false,
    publications,
  })),
  on(SalesMaterialsApiActions.retrievePublicationsFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
);
