<mat-card>
  <mat-card-content>
    <div class="grid-container">
      @for (publication of publications(); track publication.title) {
        <div class="grid-item" (click)="onClick.emit(publication)">
          <img
            [ngSrc]="publication.imageUrl || ''"
            [width]="253"
            [height]="328"
            [alt]="publication.title"
          />
          <div class="mat-title-small">{{ publication.title }}</div>
        </div>
      }
    </div>
  </mat-card-content>
</mat-card>
