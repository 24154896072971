import { Component, input, output } from '@angular/core';
import { MatCard, MatCardContent } from '@angular/material/card';
import { NgOptimizedImage } from '@angular/common';
import { MatGridList, MatGridTile, MatGridTileText } from '@angular/material/grid-list';
import { PublicationJson } from '@cportal/api';

@Component({
  selector: 'vbn-publications',
  standalone: true,
  imports: [MatCard, MatCardContent, NgOptimizedImage, MatGridList, MatGridTile, MatGridTileText],
  templateUrl: './publications.component.html',
  styleUrl: './publications.component.scss',
})
export class PublicationsComponent {
  publications = input.required<readonly PublicationJson[]>();
  onClick = output<PublicationJson>();
}
