export const downloadFile = (link: string) => {
  fetch(link)
    .then(res => res.blob())
    .then(blob => {
      downloadBlob(blob);
    });
};

const downloadBlob = (blob: Blob) => {
  const blobUrl = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = blobUrl;
  link.download = blobUrl.split('/').reverse()[0];
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
