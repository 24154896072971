import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { PublicationJson } from '@cportal/api';

export const SalesMaterialsApiActions = createActionGroup({
  source: 'Sales Materials API',
  events: {
    'Retrieve Publications': emptyProps(),
    'Retrieve Publications Success': props<{ publications: readonly PublicationJson[] }>(),
    'Retrieve Publications Failure': props<{ error: any }>(),
  },
});
