import { createFeatureSelector, createSelector } from '@ngrx/store';
import { salesMaterialsFeatureKey, SalesMaterialsState } from './sales-materials.reducer';

export const selectSalesMaterialsState =
  createFeatureSelector<Readonly<SalesMaterialsState>>(salesMaterialsFeatureKey);

export const selectSalesMaterialsIsLoading = createSelector(
  selectSalesMaterialsState,
  state => state.isLoading,
);
export const selectSalesMaterialsPublications = createSelector(
  selectSalesMaterialsState,
  state => state.publications,
);
